/*
 * Usage:
 *   add data-introducing-transition='fade-in|left-fade-in|right-fade-in' to html elements
 *
 *   * These elements above viewport (which considered be seen already)
 *     will start transition (appear) all together
 *   * These elements in viewport
 *     will start transition (fade-in) one by one, from top to bottom
 *   * data-introducing-order-offset='100' can be used to adjust the order of transition
 *   * we have turbolinks installed, to avoid elements blinking while switching pages,
 *     add data-turbolinks-permanent and id to introducing-transition elements
 */

import './introducing-transitions.scss';

const sortIntroducingDOMs = (doms) => (
  doms.map((dom) => {
    const { top, left } = dom.getBoundingClientRect();
    return [
      top + parseInt(dom.dataset.introducingOrderOffset || '0', 10),
      left,
      dom,
    ];
  }).sort(([aTop, aLeft], [bTop, bLeft]) => {
    const topComp = aTop - bTop;
    if (topComp !== 0) {
      return topComp;
    }
    return aLeft - bLeft;
  }).map((d) => d[2])
);

let scrollHandler;
const timeouts = {};

const introducingTransitions = () => {
  const introducingDOMs = sortIntroducingDOMs([
    ...document.querySelectorAll('[data-introducing-transition]:not(.introducing-done)').values(),
  ]);

  let transitioning = false;
  let scrolled = false;
  const detectAndIntroduce = () => {
    transitioning = true;
    scrolled = false;

    let index = 0;
    while (
      index < introducingDOMs.length
      && introducingDOMs[index].getBoundingClientRect().bottom < 0
    ) {
      introducingDOMs[index].classList.add('introducing-done');
      index += 1;
    }
    if (
      index < introducingDOMs.length
      && introducingDOMs[index].getBoundingClientRect().top < window.innerHeight
    ) {
      introducingDOMs[index].classList.add('introducing-done');
      index += 1;
    }
    if (index > 0) {
      introducingDOMs.splice(0, index);
    }

    if (introducingDOMs.length > 0) {
      timeouts.nextDetect = setTimeout(() => {
        if (scrolled || index > 0) {
          detectAndIntroduce();
        } else {
          transitioning = false;
        }
      }, 300);
    }
  };

  detectAndIntroduce();
  scrollHandler = () => {
    if (transitioning) {
      scrolled = true;
      return;
    }
    detectAndIntroduce();
  };
  window.addEventListener('scroll', scrollHandler);
};

const mountIntroducingTransitions = () => {
  timeouts.introducingTransitions = setTimeout(introducingTransitions, 500);
};

export const unmountIntroducingTransitions = () => {
  if (scrollHandler) {
    window.removeEventListener('scroll', scrollHandler);
    scrollHandler = null;
  }
  Object.values(timeouts).forEach((t) => clearTimeout(t));
};

export default mountIntroducingTransitions;
