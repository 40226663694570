let pageTopBtns;

const scrollToTop = () => {
  window.scrollTo({
    top: 0,
    behavior: 'smooth',
  });
};

const mountPageTop = () => {
  pageTopBtns = document.querySelectorAll('.page-top-btn');
  pageTopBtns.forEach((btn) => {
    btn.addEventListener('click', scrollToTop);
  });
};

export const unmountPageTop = () => {
  pageTopBtns.forEach((btn) => {
    btn.removeEventListener('click', scrollToTop);
  });
  pageTopBtns = [];
};

export default mountPageTop;
