/*
 * ## Social SDKs
 * facebook: https://developers.facebook.com/docs/plugins/share-button/
 * twitter: https://publish.twitter.com/?buttonType=TweetButton&widget=Button
 * line: https://social-plugins.line.me/zh_TW/how_to_install
 *
 * ### FB root
 * app/views/layouts/partials/_fbroot.html.erb
 */

const FBSDK_SCRIPT = 'https://connect.facebook.net/zh_TW/sdk.js#xfbml=1&version=v9.0';
const TWITTER_WIDGETS_SCRIPT = 'https://platform.twitter.com/widgets.js';
const LINE_SOCIAL_PLUGIN_SCRIPT = 'https://www.line-website.com/social-plugins/js/thirdparty/loader.min.js';

let initialized = false;

const afterTurbolinkPageChaged = () => {
  if (window.LineIt) {
    window.LineIt.loadButton();
  }
  if (window.FB) {
    window.FB.XFBML.parse();
  }
  if (window.twttr.widgets) {
    window.twttr.widgets.load();
  }
};

const loadSocialSdks = () => {
  if (initialized) {
    afterTurbolinkPageChaged();
    return;
  }

  const [fbScriptTag, twitterScriptTag, lineScriptTag] = (
    [FBSDK_SCRIPT, TWITTER_WIDGETS_SCRIPT, LINE_SOCIAL_PLUGIN_SCRIPT].map((scriptUrl) => {
      const scriptTag = document.createElement('script');
      scriptTag.src = scriptUrl;
      scriptTag.async = true;
      scriptTag.defer = true;
      scriptTag.crossorigin = 'anonymous';

      return scriptTag;
    })
  );
  fbScriptTag.setAttribute('nonce', 'zFcPfZ24');

  [fbScriptTag, twitterScriptTag, lineScriptTag].forEach((scriptTag) => {
    document.head.appendChild(scriptTag);
  });

  /* Twitter tweet tracking
   * https://developer.twitter.com/en/docs/twitter-for-websites/javascript-api/guides/set-up-twitter-for-websites
   * https://developer.twitter.com/en/docs/twitter-for-websites/javascript-api/guides/javascript-api
   */
  const twttr = window.twttr || {};
  window.twttr = twttr;
  // eslint-disable-next-line no-underscore-dangle
  twttr._e = [() => {
    twttr.events.bind('tweet', () => {
      if (window.dataLayer) {
        window.dataLayer.push({
          event: 'tweet',
        });
      }
    });
  }];

  initialized = true;
};

export default loadSocialSdks;
