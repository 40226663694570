import './nav.scss';

let mobileNavToggle;
let mobileNavCross;
let mobileNavToggleHandler;
let scrollHandler;

const mountNav = () => {
  mobileNavToggle = document.getElementById('mobile-nav-toggle');
  mobileNavCross = document.getElementById('mobile-nav-cross');
  let mobileNavOpened = false;

  mobileNavToggleHandler = () => {
    const navMobileContent = document.getElementById('nav-mobile-content');
    if (!navMobileContent) {
      return;
    }
    mobileNavOpened = !mobileNavOpened;
    if (mobileNavOpened) {
      navMobileContent.style.height = '100vh';
    } else {
      navMobileContent.style.removeProperty('height');
    }
  };

  if (mobileNavToggle) {
    mobileNavToggle.addEventListener('click', mobileNavToggleHandler);
  }
  if (mobileNavCross) {
    mobileNavCross.addEventListener('click', mobileNavToggleHandler);
  }

  const nav = document.getElementById('nav');
  if (nav) {
    let navOpened;
    const setNavOpened = (navShouldOpen) => {
      if (navShouldOpen !== navOpened) {
        if (navShouldOpen) {
          nav.style.removeProperty('top');
        } else {
          nav.style.top = `-${nav.offsetHeight}px`;
        }
        navOpened = navShouldOpen;
      }
    };
    setNavOpened(true); // force nav to be opened at init

    let scrollDiff = 0;
    let lastScrollY = window.scrollY;

    scrollHandler = () => {
      const newScrollDiff = window.scrollY - lastScrollY;
      lastScrollY = window.scrollY;

      if (scrollDiff === 0 || ((newScrollDiff > 0) === (scrollDiff > 0))) {
        scrollDiff += newScrollDiff;
      } else {
        scrollDiff = 0;
      }

      const navShouldOpen = mobileNavOpened || window.scrollY <= 0 || (
        Math.abs(scrollDiff) > nav.offsetHeight / 2 ? (scrollDiff < 0) : navOpened
      );

      setNavOpened(navShouldOpen);
    };

    window.addEventListener('scroll', scrollHandler);
  }
};

export const unmountNav = () => {
  if (mobileNavToggleHandler) {
    if (mobileNavToggle) {
      mobileNavToggle.removeEventListener('click', mobileNavToggleHandler);
    }
    if (mobileNavCross) {
      mobileNavCross.removeEventListener('click', mobileNavToggleHandler);
    }
  }

  if (scrollHandler) {
    window.removeEventListener('scroll', scrollHandler);
  }
};

export default mountNav;
