let registeredMoreLoaders = [];

const mountMoreLoader = () => {
  document.querySelectorAll('.more-loader-btn').forEach((dom) => {
    const {
      moreLoaderContainer: containerQuery,
      moreLoaderItem: itemQuery,
      moreLoaderNext: nextQuery,
    } = dom.dataset;

    const container = document.querySelector(containerQuery);
    if (!(dom.dataset.moreLoaderHref && container && itemQuery && nextQuery)) return;

    if (dom.href) {
      dom.href = dom.dataset.moreLoaderHref;
    }

    let loading = false;

    const eventHandler = async (event) => {
      event.preventDefault();
      if (loading) return;
      loading = true;
      const oriTextContent = dom.textContent;
      dom.textContent = 'Loading...';
      try {
        const request = await fetch(dom.dataset.moreLoaderHref);
        const htmlDoc = (new DOMParser()).parseFromString(await request.text(), 'text/html');
        htmlDoc.querySelectorAll(itemQuery).forEach((item) => {
          container.appendChild(item);
        });
        const nextBtn = htmlDoc.querySelector(nextQuery);
        if (nextBtn) {
          dom.dataset.moreLoaderHref = nextBtn.dataset.moreLoaderHref;
          if (dom.href) {
            dom.href = nextBtn.dataset.moreLoaderHref;
          }
        } else {
          registeredMoreLoaders = registeredMoreLoaders.filter(({ dom: d }) => dom === d);
          dom.remove();
        }
      } finally {
        dom.textContent = oriTextContent;
        loading = false;
      }
    };

    dom.addEventListener('click', eventHandler);
    registeredMoreLoaders.push({ dom, eventHandler });
  });
};

export const unmountMoreLoader = () => {
  registeredMoreLoaders.forEach(({ dom, eventHandler }) => {
    dom.removeEventListener('click', eventHandler);
  });
  registeredMoreLoaders = [];
};

export default mountMoreLoader;
