// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import '../assets';

import Prism from 'prismjs';
import mountNav, { unmountNav } from '../application/nav';
import mountMoreLoader, { unmountMoreLoader } from '../application/moreLoader';
import mountPageTop, { unmountPageTop } from '../application/pageTop';

import mountHomeCarousel, { unmountHomeCarousel } from '../home/carousel';
import mountIntroducingTransitions, { unmountIntroducingTransitions } from '../home/introducingTransitions';

import loadSocialSdks from '../application/socialSdks';

import '../application/tailwindcss.scss';
import '../application/shared.scss';
import '../application/resource-content.scss';
import '../application/social-links.scss';

import '../home/index.scss';
import '../pages/our_vision.scss';
import '../pages/privacy_policy.scss';
import '../testimonials/index.scss';
import '../articles/index.scss';
import '../news/index.scss';
import '../subscribes/subscribes.scss';
import '../consultations/index.scss';

require('turbolinks').start();

document.addEventListener('turbolinks:load', () => {
  Prism.highlightAll();
  mountNav();
  mountMoreLoader();
  mountPageTop();

  loadSocialSdks();

  mountHomeCarousel();
  mountIntroducingTransitions();
});

document.addEventListener('turbolinks:before-cache', () => {
  unmountNav();
  unmountMoreLoader();
  unmountPageTop();

  unmountHomeCarousel();
  unmountIntroducingTransitions();
});
