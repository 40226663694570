import Glide from '@glidejs/glide';

let glide;

const mountHomeCarousel = () => {
  const homeCarouselDiv = document.getElementById('home-carousel');
  const slides = document.querySelectorAll('.glide__slide');

  if (homeCarouselDiv) {
    glide = new Glide(homeCarouselDiv, {
      type: 'carousel',
      gap: 0,
      ...(slides.length > 1 ? {
        autoplay: 5000,
        hoverpause: true,
      } : {}),
    }).mount();
  }
};

export const unmountHomeCarousel = () => {
  if (glide) {
    glide.destroy();
    glide = null;
  }
};

export default mountHomeCarousel;
